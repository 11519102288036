@font-face {
  font-family: 'Selecta';
  src: url(https://vivi-web.poc.one-platform.srt.global/font/selecta_thin.ttf) format('truetype');
  src: url(https://vivi-web.poc.one-platform.srt.global/font/selecta_thin.otf) format('opentype');
  font-style: normal;
  font-weight: 100;
}

/* Light */
@font-face {
  font-family: 'Selecta';
  src: url(https://vivi-web.poc.one-platform.srt.global/font/selecta_light.ttf) format('truetype');
  src: url(https://vivi-web.poc.one-platform.srt.global/font/selecta_light.otf) format('opentype');
  font-style: normal;
  font-weight: 300;
}

/* Normal */
@font-face {
  font-family: 'Selecta';
  src: url(https://vivi-web.poc.one-platform.srt.global/font/selecta_regular.ttf) format('truetype');
  src: url(https://vivi-web.poc.one-platform.srt.global/font/selecta_regular.otf) format('opentype');
  font-style: normal;
  font-weight: 400;
}

/* Medium */
@font-face {
  font-family: 'Selecta';
  src: url(https://vivi-web.poc.one-platform.srt.global/font/selecta_medium.ttf) format('truetype');
  src: url(https://vivi-web.poc.one-platform.srt.global/font/selecta_medium.otf) format('opentype');
  font-style: normal;
  font-weight: 500;
}

/* Bold */
@font-face {
  font-family: 'Selecta';
  src: url(https://vivi-web.poc.one-platform.srt.global/font/selecta_bold.ttf) format('truetype');
  src: url(https://vivi-web.poc.one-platform.srt.global/font/selecta_bold.otf) format('opentype');
  font-style: normal;
  font-weight: 700;
}

/* Black */
@font-face {
  font-family: 'Selecta';
  src: url(https://vivi-web.poc.one-platform.srt.global/font/selecta_black.ttf) format('truetype');
  src: url(https://vivi-web.poc.one-platform.srt.global/font/selecta_black.otf) format('opentype');
  font-style: normal;
  font-weight: 900;
}

body {
  font-family: Selecta, sans-serif;
  -webkit-font-smoothing: antialiased;
}

.marker {
  width: 1em;
  height: 1em;
}

.marker.origin {
  border-radius: 2em;
  background: #212121;
  border: 2px solid #bbdefb;
}

.marker.destination {
  border-radius: 2em;
  background: #f5f5f5;
  border: 2px solid #212121;
}

.marker.me {
  border-radius: 2em;
  background: #42a5f5;
  border: 2px solid #bbdefb;
}

#Cardinal-Modal {
  z-index: 10000001;
}

#Cardinal-Overlay {
  z-index: 10000000;
}